<template>
  <div class="surface-card p-4 shadow-2 border-round">
    <Steps :model="items" :readonly="true" exact>
      <template #item="{item}">
        <router-link :to="item.to" custom >
          <ul class="list-none p-0 m-0 flex flex-column md:flex-row">
            <li class="relative mr-0 md:mr-8 flex-auto" v-if="item.id === 1">
              <div class="border-1 border-300 border-round p-3 surface-card flex flex-column md:flex-row align-items-center z-1">
                <i class="pi pi-building text-cyan-500 text-2xl md:text-4xl mb-2 md:mb-0 mr-0 md:mr-3"></i>
                <div>
                  <div class="text-900 font-medium mb-1">Dados da Clínica</div>
                  <span class="text-600 text-sm hidden md:block">Informe os dados da clínica</span>
                </div>
              </div>
              <div class="w-full absolute top-50 left-100 surface-300 hidden md:block" style="transform: translateY(-50%); height: 2px"></div>
            </li>
            <li class="relative mr-0 md:mr-8 flex-auto" v-if="item.id === 2">
              <div class="border-2 border-blue-500 border-round p-3 surface-card flex flex-column md:flex-row align-items-center z-1">
                <i class="pi pi-user text-blue-600 text-2xl md:text-4xl mb-2 md:mb-0 mr-0 md:mr-3"></i>
                <div>
                  <div class="text-blue-600 font-medium mb-1">Dados do Usuário</div>
                  <span class="text-600 text-sm hidden md:block">Adicione os dados de acesso.</span>
                </div>
              </div>
              <div class="w-full absolute top-50 left-100 surface-300 hidden md:block" style="transform: translateY(-50%); height: 2px"></div>
            </li>
            <li class="relative flex-auto" v-if="item.id === 3">
              <div class="border-1 border-300 border-round p-3 surface-50 flex flex-column md:flex-row align-items-center z-1">
                <i class="pi pi-check-circle text-green-500 text-2xl md:text-4xl mb-2 md:mb-0 mr-0 md:mr-3"></i>
                <div>
                  <div class="text-900 font-medium mb-1">Concluido</div>
                  <span class="text-600 text-sm hidden md:block">Parabéns, você ja pode acessar sua conta.</span>
                </div>
              </div>
            </li>
          </ul>
        </router-link>

      </template>
    </Steps>
    <router-view v-slot="{Component}" custom :formData="formObject" @prevPage="prevPage($event)" @nextPage="nextPage($event)" @complete="complete">
      <keep-alive>
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </div>
</template>

<script>

export default {
  name: "register",
  created() {

  },
  async mounted() {

  },
  data() {
    return {
      items: [{
        label: 'Criar Despesa',
        to: '/register/step1',
        id: 1,
        isActive: false,
        isExactActive: false
      },
        {
          id: 2,
          label: 'Produtos',
          to: '/register/step2',
          isActive: false,
          isExactActive: false
        },
        {
          id: 3,
          label: 'Resumo',
          to: '/register/step3',
          isActive: false,
          isExactActive: false
        }],
      formObject: {},
      inventoryService: null,
      isActive: false,
      isExactActive: false,
      clinical: null,
      user: {
        clinical: {

        }
      },
      clinicalService: null,
      userService: null,
      cepService: null
    }
  },
  methods: {

    nextPage(event) {
      for (let field in event.formData) {
        this.formObject[field] = event.formData[field];
      }
      this.$router.push(this.items[event.pageIndex + 1].to);
    },
    prevPage(event) {
      this.$router.push(this.items[event.pageIndex - 1].to);
    },
    async complete() {

    }

  }
}
</script>

<style scoped lang="scss">

::v-deep(b) {
  display: block;
}

::v-deep(.p-card-body) {
  padding: 2rem;
}

</style>
